<template>
  <div id="users">
    <div class="container-fluid">
      <!-- Add Edit Section -->
      <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('name')"
                      v-model="filterData.name"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col cols="3">
                    <b-form-input
                      type="text"
                      :placeholder="$t('email')"
                      v-model="filterData.email"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-button variant="primary" @click="filtering" size="xl">{{$t('search')}}</b-button>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
      <div class="add-section" v-if="canAccess(['store-user','update-user'])">
        <b-modal id="users-modal" @hidden="closeModal" :cancel-title="$t('close')" hide-header-close
                 :ok-title="$t('save')"
                 :title="$t('sidebar.users')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <div class="feild">
              <label for="">{{ $t('name') }}</label>
              <b-form-input
                type="text"
                v-model="payload.name"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('phone') }}</label>
              <b-form-input
                type="number"
                v-model="payload.phone"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('email') }}</label>
              <b-form-input
                type="text"
                v-model="payload.email"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('password') }}</label>
              <b-form-input
                type="password"
                v-model="payload.password"
                required
              ></b-form-input>
            </div>
          </b-form>
        </b-modal>
        <b-modal id="send-notification-modal" hide-header-close :cancel-title="$t('close')" :ok-title="$t('save')" :title="$t('send_notification')" @ok="postNotification">
          <b-form class="d-grid grid-cols-1 gap-5">
            <b-row>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('title') }}</label>
                  <b-form-input
                    v-model="notification.title"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="feild">
                  <label for="">{{ $t('content') }}</label>
                  <b-form-input
                    v-model="notification.body"
                    required
                  ></b-form-input>
                </div>
              </b-col>
              <b-col>
                <label for="">{{ $t('notification_type') }}</label>
                <b-form-select v-model="notification.user_type" class="mb-3">
                  <b-form-select-option value="all">
                    {{ $t('all') }}
                  </b-form-select-option>
                  <b-form-select-option value="some" :disabled="selected.length < 1">
                    {{ $t('only_selected_client') }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user-list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="canAccess(['store-client'])" variant="secondary" @click="$bvModal.show('send-notification-modal')" size="sm" class="mx-2">
              {{
                $t('send_notification')
              }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-table responsive v-if="canAccess(['get-user','update-user','delete-user'])" :items="users.data"
                     :fields="fields" class="mb-0 table-borderless">
              <template v-slot:cell(checkbox)="data">
                <b-form-checkbox
                  :checked="selected.includes(data.item.id)"
                  @change="toggleSelected(data.item.id)"
                />
              </template>
              <template v-slot:cell(is_active)="{item}">
                <b-form-checkbox :checked="item.is_active" name="check-button" switch @change="$store.dispatch('toggleIsActive', {id: item.id, isActive: item.is_active})">
                </b-form-checkbox>
              </template>
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button v-b-tooltip.top="$t('edit')" variant=" iq-bg-success"
                            @click="update(data.item)" size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                </div>
              </template>

            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              v-model="filterData.page"
              :total-rows="users.total"
              :per-page="users.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'governorates',
  data () {
    return {
      selected: [],
      filterData: {
        page: 1,
        name: '',
        email: ''
      },
      payload: {
        name: '',
        phone: '',
        password: '',
        email: ''
      },
      notification: {
        title: '',
        body: '',
        user_type: 'all'
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: '#',
          key: 'checkbox'
        },
        {
          label: this.$t('name'),
          key: 'name'
        },
        {
          label: this.$t('phone'),
          key: 'phone'
        },
        {
          label: this.$t('email'),
          key: 'email'
        },
        {
          label: this.$t('hotel'),
          key: 'hotel.title'
        },
        {
          label: this.$t('user_status'),
          key: 'is_active'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    toggleSelected (id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter(i => i !== id)
      } else {
        this.selected.push(id)
      }
    },
    filtering () {
      this.filterData.page = 1
      this.getHotelUsers(this.filterData)
    },
    update (data) {
      Object.assign(this.payload, {
        name: data.name,
        phone: data.phone,
        email: data.email,
        id: data.id
      })
      this.imgPreview = ''
      this.$bvModal.show('users-modal')
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.updateUsers({
        id: this.payload.id,
        payload: this.returnData(this.payload)
      }).then(() => {
        this.$bvModal.hide('users-modal')
        this.payload = this.resetObject(this.payload)
        this.getHotelUsers()
      })
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    },
    postNotification () {
      const form = {
        ...this.notification,
        ...this.notification.user_type === 'some' ? { userIds: this.selected } : {},
        type: 'hotels'
      }
      this.$store.dispatch('sendNotificationToUser', form)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getHotelUsers(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getHotelUsers()
  }
}
</script>
<style>

.add-new-img {
  font-size: 18px;
  font-weight: bold;
  color: #ccc;
  height: 200px;
  border: 2px dashed #ccc;
  cursor: pointer;
}

.img-preview {
  height: 200px;
  border: 2px dashed #ccc;
}

.img-preview img {
  object-fit: contain;
}

</style>
